.certificates {
    padding: 50px 0 130px;
    &_title {
        font-size: 36px;
        margin: 0 0 30px;
        padding: 0; }
    &_box {
        display: flex;
        flex-wrap: wrap;
        &_item {
            width: 25%;
            margin-bottom: 20px;
            padding-left: 10px;
            padding-right: 10px;
            box-sizing: border-box;
            &_inner {
                border: 1px solid #E0E0E0;
                overflow: hidden;
                height: 314px;
                position: relative;
                cursor: pointer; }
            &_image {
                img {
                    display: block;
                    max-width: 100%; } }
            &_title {
                background: #343434;
                box-sizing: border-box;
                padding: 15px 25px;
                font-size: 18px;
                color: #ffffff;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%; } } } }
