@import '../global/_variables';
@import '../global/_mixins';

.footer-wrapper {
  background-color: #1d1d1d;
  // max-width: 1640px; // fix width point
}

.footer-wrapper a {
  text-decoration: none;
}

.footer-container {
  max-width: $max-width;
  @include container;

  @include for-desktop {
    padding: 18px 154px 18px 74px;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
