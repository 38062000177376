@import '../global/_variables';
@import '../global/_mixins';

.footer-address {
  padding: 24px 36px 32px;

  background-color: #1d1d1d;

  font-family: $base-font;
  @include font-size(14px, 18px);
  color: $secondary;
  text-align: center;

  @include for-desktop {
    width: 303px;
    padding: 0;
  }

  &__side-menu {
    @include for-desktop {
      display: none;
    }
  }

  &:hover {
    color: $novelty;
  }
}
