@import 'global/mixins';
@import 'global/variables';
@import 'header/styles';
@import 'cart/styles';
@import 'side-bar/styles';
@import 'side-menu/styles';
@import 'side-menu-profile/styles';
@import 'secondary-nav/styles';
@import 'footer/styles';
@import 'footer-phone/styles';
@import 'footer-address/styles';
@import 'footer-social-media/styles';
@import 'slider/styles';
@import 'promotion/styles';
@import 'articles/styles';
@import 'certificates/styles';

body {
    margin: 0;
    font-family: $base-font;
}

.content {
    min-height: calc(100vh - 86px - 92px - 64px - 43px);
    @include for-desktop {
        min-height: calc(100vh - 86px - 92px - 64px - 43px + 64px + 84px - 24px);
        margin-top: 81px;
    }
    background-color: $secondary;
}

.main {
    @include for-desktop {
        position: relative;
        top: 0;
        left: 81px;
        width: calc(100% - 81px);
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

#modal-wrap {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: #000;
    opacity: 0.5;
    cursor: pointer;
    display: none;
}
.modal-dialog {
    position: fixed;
    display: none;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    z-index: 101;
    max-width: 540px;
    width: 100%;
    padding-top: 80px;
}
@media only screen and (max-width: 740px)  {
    .modal-dialog {
        top: 0;
        position: fixed;
        display: none;
        bottom: 0;
        padding: 0;
    }
}
