@import '../global/_variables';
@import '../global/_mixins';

.footer-social-media {
  padding: 18px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &__column {
    height: 16vh;
    padding: 40px 0;
    flex-direction: column;
    justify-content: space-around;
  }

  &__desktop {
    @include for-desktop {
      display: none;
    }
  }

  background-color: $tertiary;

  &--link {
    margin: 0 18px;

    &:hover {
      color: $novelty;
    }
  }
}
