@mixin container {
  margin: 0 auto;
}

@mixin font-size($size: 14px, $height: 14px) {
  font-size: $size;
  line-height: $height;
}

@mixin font-size-14 {
  @include font-size(14px, 14px)
}

@mixin font-size-16 {
  @include font-size(16px, 16px)
}

@mixin font-size-18 {
  @include font-size(18px, 18px)
}

@mixin font-size-20 {
  @include font-size(20px, 20px)
}

@mixin font-size-22 {
  @include font-size(22px, 22px)
}

@mixin for-desktop {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin for-tablet {
  @media (min-width:768px) and (max-width: 1023px) {
    @content;
  }
}

@mixin for-smartphone {
  @media (min-width:480px) and (max-width: 767px) {
    @content;
  }
}

@mixin for-mobile {
  @media (max-width: 479px) {
    @content;
  }
}
