@import '../global/variables';
@import '../global/mixins';

.promotion-wrapper {
  background-color: $secondary;
}

.promotion {
  background: $background-primary;
  // max-width: 1110px; // fix width point
  max-width: 100%; // fix width point
  @include container;
  // padding: 40px 20px; // fix width point
  padding: 40px 40px; // fix width point
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  &--title {
    margin-bottom: 10px;

    @include for-mobile {
      margin-left: 0;
    }

    @include for-desktop {
      @include font-size(36px, 36px);
    }

    font-family: $bold-font;
    @include font-size-20;
    color: $secondary;
  }
}
