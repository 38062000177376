@import '../global/_variables';
@import '../global/_mixins';

.footer-phone {
  padding-top: 42px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  background-color: #1d1d1d;

  @include for-desktop {
    padding-top: 0;
    order: 1;
  }

  &__side-menu {
    @include for-desktop {
      display: none;
    }
  }

  &--phone {
    font-family: $bold-font;
    @include font-size-22;
    color: $secondary;

    &:hover {
      color: $novelty;
    }
  }

  &--email {
    margin-top: 4px;
    font-family: $bold-font;
    @include font-size-18;
    color: $secondary;

    &:hover {
      color: $novelty;
    }
  }
}
