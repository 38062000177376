@import '../global/_variables';
@import '../global/_mixins';

.slider-wrapper {
  background-color: $secondary;
}

.slider-container {
  // max-width: $max-width; // fix width point
  max-width: 1640px; // fix width point
  position: relative;
  @include container;
  padding: 0 30px 0;

  @include for-tablet {
    padding: 0;
  }

  @include for-smartphone {
    padding: 0;
  }

  @include for-mobile {
    padding: 0;
  }

}

@media (min-width:1024px) and (max-width: 1289px) {
  .slider-container {
    padding: 0;
  }
}

.slider-content {
  display: flex;
  width: 100%;
}

.slider-content a {
  width: 100%;
}

.slider-content-image {
  // height: 420px; // fix width point
  height: 620px; // fix width point
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.slider-content img {
  width: 100%;
}

.slider-container ul {
  margin: 0;
  padding: 0;
}

.slider-container ul li {
  list-style-type: none;
  transition: all 1s linear;
}

.visually-hidden {
  opacity: 0;
}

.hidden {
  display: none;
}

.slider-details {
  flex: 0.5 0 190px;

  font-family: $base-font;
  color: $secondary;

  &--novelty {
    max-width: 154px;
    padding: 13px 42px;
    background-color: $novelty;

    box-sizing: border-box;

    text-align: center;
    vertical-align: center;

    @include font-size-14;
  }

  &--title {
    margin-top: 30px;

    @include font-size-20;
    font-family: $bold-font;

    @include for-desktop {
      @include font-size(36px, 36px);
    }
  }

  &--description {
    margin-top: 10px;

    @include font-size-16;

    @include for-desktop {
      @include font-size(24px, 24px);
    }
  }

  &--link {
    display: block;
    margin-top: 20px;

    @include font-size-18;
    color: $secondary;

    text-decoration: none;

    &:hover {
      color: $novelty;
    }
  }

  &--link-icon {
    margin-left: 12px;
  }
}

.slider-image {
  max-width: 34vw;
  height: 232px;
  object-fit: cover;
  object-position: 0 0;
}

.slider-toggles {
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
  margin-left: 20px;

  display: flex;
  justify-content: space-between;
  @include for-desktop {
    justify-content: flex-start;
  }

  color: $secondary;

  &--item {
    padding: 7px 11px;

    border: 1px solid $secondary;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    @include for-desktop {
      margin-right: 20px;
    }
    &:hover {
      border-color: $tertiary;
    }
  }

  &--icon {
    width: 6px;
    height: 14px;
  }
}

.slider-tag-wrap {
  padding-top: 20px;
}

.slider-tag {
  max-width: 150px;
  padding: 12px 0;
  text-align: center;
  margin-left: 20px;
  font-size: 0.875em;
}

.slider-prev-btn, .slider-next-btn {
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    transform: rotate(-90deg);
    position: absolute;
    width: 60px;
    height: 60px;
    cursor: pointer;
    display: block;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 100%;
    display: flex;
    align-items: center;
}

.slider-prev-btn img, .slider-next-btn img {
    display: block;
    margin: auto;
}

.slider-prev-btn {
    left: -30px;
}

.slider-next-btn {
    right: -30px;
}
