@import '../global/variables';
@import '../global/mixins';

.side-bar {
  width: 80px;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border-right: 1px solid $border;

  background-color: $tertiary;

  @include for-mobile {
    display: none;
  }

  @include for-smartphone {
    display: none;
  }

  @include for-tablet {
    display: none;
  }

  &--menu {
    padding: 29px;
  }

  &--menu-icon {
    height: 22px;
    width: 22px;

    cursor: pointer;
  }
}
