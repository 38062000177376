@import '../global/variables';
@import '../global/mixins';

.header-wrapper {
  background-color: $tertiary;
  border-bottom: 1px solid $border;

  @include for-desktop {
    position: fixed;
    top: 0;
    left: 81px;
    width: calc(100% - 81px); // fix width point
    // width: calc(100% - 225px); // fix width point
    // max-width: 1640px; // fix width point
    // z-index: 1;
    z-index: 5;
  }
}

.header-container {
  @include container;
  padding: 19px;

  @include for-desktop {
    padding: 0;
    width: calc(100% - 325px);
  }

  max-width: 1140px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-cart {
  position: fixed;
  top: 0;
  right: 0;
  width: 80px;
  height: 80px;
  background: #fff;
  display: none;
  cursor: pointer;

  @include for-desktop {
    display: block;
  }

  img {
    padding: 29px;
  }
}

.header--menu {
  color: $secondary;
}

.header--logo-icon {
  cursor: pointer;

  @include for-desktop {
    padding-top: 31px;
    padding-bottom: 31px;
  }

  &:hover img {
    fill: $tertiary
  }
}

.header-profile {
  display: none;

  @include for-desktop {
    display: flex;
    align-items: center;
  }

  &--img-wrap {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 50px;
    height: 50px;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
  }

  &--icon {
    margin: 14px 17px 18px 15px;
  }

  &--info {
    width: 260px;
    margin-top: -60px;
    position: absolute;
    right: 0;
    background: #fff;
    border: 1px solid #D0D0D0;
    display: none;
  }

  &--info-row {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 4px;
    margin-bottom: 4px;
  }

  &--info a {
    text-decoration: none;
    color: $background-primary;
  }

  &--info-btn {
    border-top: 1px solid $background-primary;
    padding: 16px;
    font-size: 0.9em;
    &-noborder {
        border-top: 0;
        padding-top: 0;
    }
    &-bold {
        font-size: 18px;
    }
    &-box {
        display: flex;
        align-items: center;
        border-top: 0;
        padding-top: 0;
    }
    &-bonus {
        margin: 0 15px;
    }
  }

  &--info-name {
    font-weight: bold;
    font-size: 1em;
    margin-top: 16px;
    flex: 1;
    padding-left: 16px;
  }

  &--img-wrap:hover &--info {
    display: block;
  }
}

.header-login {
  display: none;

  @include for-desktop {
    display: flex;
    align-items: center;
  }

  &--icon {

  }

  &--link {
    font-family: $base-font;
    @include font-size-14;
    color: $background-primary;
    text-decoration: none;
    margin-left: 6px;
    margin-right: 6px;

    &:hover {
      color: $novelty;
    }
  }

  &--divider {
    @include font-size-14;
    color: $background-primary;
  }
}

// login | register dialog
.auth-dialog {
  background: #FFFFFF;
}

.auth-dialog-header {
  background: #FFF500;
  color: #343434;
  padding: 24px 36px;
  display: flex;
  font-family: "Europe Bold", serif;
  font-size: 1.25em;
}

.auth-dialog-header-title {
  flex: 1;
}

.auth-dialog-header img {
  width: 24px;
  height: 24px;
  padding: 1px;
  cursor: pointer;
}

.auth-dialog h1 {
  font-family: "Europe Bold", serif;
}

/* form */
.auth-dialog form {
  margin-left: 36px;
  margin-right: 36px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.auth-dialog form .form-group label {
  display: block;
  margin-left: 10px;
  font-size: 1.1em;
  padding: 10px;
}

.auth-dialog form .form-check {
  display: flex;
}

.auth-dialog form .form-check-input {
  margin-top: 20px;
  width: 40px !important;
}

.auth-dialog form .form-group input {
  display: block;
  background: #e7e7e7;
  border: none;
  padding: 16px;
  font-size: 1.15em;
  width: calc(100% - 32px);
}

.auth-dialog form .btn {
  background: #FFF500;
  font-family: "Europe Bold", serif;
  color: #343434;
  font-size: 1.25em;
  margin-top: 20px;
  width: 100%;
  border: none;
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: center;
  cursor: pointer;
}

.auth-recover-password {
    display: block;
    text-decoration: none;
    margin-top: 20px;
}

@media only screen and (max-width: 740px)  {
  .auth-dialog {
    height: 100%;
  }
  .auth-dialog form {
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .auth-dialog-header {
    padding-right: 20px;
    padding-left: 20px;
  }
}
