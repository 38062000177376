.cart {
  height: 22px;
  width: 22px;
  cursor: pointer;

  &--icon {
  }

  &__desktop {
    @include for-desktop {
      display: none;
    }
  }
}
