@import '../global/variables';
@import '../global/mixins';

.secondary-nav {
  display: none;

  width: 344px;
  margin: 0 16px;
  padding: 0;
  @include for-desktop {
    display: flex;
    padding: 31px 0;
    justify-content: space-between;
  }

  &--item {
    list-style-type: none;

    & a {
      font-family: $base-font;
      @include font-size-14;
      text-decoration: none;
      color: $background-primary;

      &:hover {
        color: $novelty;
      }
    }
  }
}
