@import '../global/variables';
@import '../global/mixins';

.articles-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  @include for-mobile {
    flex-direction: column;
  }
}

.article-preview {
  height: 144px;
  margin-top: 10px;
  padding: 20px 24px;
  box-sizing: border-box;

  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  background-repeat: no-repeat;
  background-size: 100%;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);

  @include for-smartphone {
    flex: 0 0 calc(50% - 16px);
    margin: 8px;
  }

  @include for-tablet {
    flex: 0 0 calc(50% - 16px);
    margin: 8px;
  }

  @include for-desktop {
    @media (max-width: 1330px) {
      flex: 0 0 calc(50% - 40px);
    }
    flex: 0 0 calc(25% - 40px);
  }

  &--title {
    width: 100%;
  }

  &--link {
    width: 100%;
    display: flex;
    justify-content: space-between;

    font-family: $base-font;
    @include font-size-16;
    color: $secondary;
    text-decoration: none;
    font-weight: bolder;

    @include for-desktop {
      @include font-size-20;
    }

    &:hover {
      color: $novelty;
    }
  }
}

.article-preview:hover {
  -webkit-filter: none;
  filter: none;
}

.article-preview:hover img {
  filter: invert(75%);
}

.article-preview:hover {
    text-decoration: none;
    color: $background-primary;
}

.article-preview:hover .article-preview--link {
  color: $background-primary;
}

.article-preview__1 {
  background-image: linear-gradient(to top, #343434 24.31%, rgba(0, 0, 0, 0) 100%),
                    url('../../../public/images/article_preview_1.jpg');
}

.article-preview__1:hover {
  background-image: linear-gradient(to top, $tertiary 24.31%, rgba(0, 0, 0, 0) 100%),
                    url('../../../public/images/article_preview_1.jpg');
}

.article-preview__2 {
  background-image: linear-gradient(to top, #343434 24.31%, rgba(0, 0, 0, 0) 100%),
                    url('../../../public/images/article_preview_2.jpg');
}

.article-preview__2:hover {
  background-image: linear-gradient(to top, $tertiary 24.31%, rgba(0, 0, 0, 0) 100%),
                    url('../../../public/images/article_preview_2.jpg');
}

.article-preview__3 {
  background-image: linear-gradient(to top, #343434 24.31%, rgba(0, 0, 0, 0) 100%),
                    url('../../../public/images/article_preview_3.jpg');
}

.article-preview__3:hover {
  background-image: linear-gradient(to top, $tertiary 24.31%, rgba(0, 0, 0, 0) 100%),
                    url('../../../public/images/article_preview_3.jpg');
}

.article-preview__4 {
  background-image: linear-gradient(to top, #343434 24.31%, rgba(0, 0, 0, 0) 100%),
                    url('../../../public/images/article_preview_4.jpg');
}

.article-preview__4:hover {
  background-image: linear-gradient(to top, $tertiary 24.31%, rgba(0, 0, 0, 0) 100%),
                    url('../../../public/images/article_preview_4.jpg');
}
