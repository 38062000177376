@import '../global/variables';
@import '../global/mixins';

.side-menu-container {
  height: calc(100% - 61px - 64px - 44px);
}

.side-menu {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: $primary;
  overflow-x: hidden;
  transition: 0.5s;

  & ul {
    padding: 0;
  }

  & ul li {
    list-style-type: none;
  }

  &--header {
    position: relative;
    padding: 24px 0 18px;
    @include for-desktop {
      padding: 31px 0;
    }

    font-family: $base-font;
    @include font-size-18;
    color: $secondary;
    text-align: center;

    background-color: $primary;
    border-bottom: 1px solid $border;
  }

  &--icon {
    height: 22px;
    width: 22px;

    cursor: pointer;

    &__desktop {
      @include for-desktop {
        display: none;
      }
    }
  }

  &--close {
    position: absolute;
    top: 20px;
    left: 25px;

    @include for-desktop {
      top: 26px;
    }
  }

  &--links {
     margin: 24px 0 0;
  }

  &--link {
    display: block;
    padding: 8px 8px 8px 50px;

    font-family: $base-font;
    @include font-size-16;
    color: $secondary;
    text-decoration: none;
    transition: 0.3s;

    &:hover {
      color: $novelty;
    }
  }

  &__shown {
    width: 100%;

    @include for-desktop {
      width: 466px;
      z-index: 2;
    }
  }
}
