/*****************************
 All rights reserved.
 AllFont.ru (c) 2011-2015
 *****************************/
@font-face {
  font-family: 'Europe Normal';
  font-style: normal;
  font-weight: 400;
  src: local('Europe Normal'), local('Europe-Normal'),
  url("/fonts/europe-normal_4ba586472fcc7ac679a9cc55dc5fa7bb.woff") format('woff'),
  url("/fonts/europe-normal_4ba586472fcc7ac679a9cc55dc5fa7bb.ttf") format('truetype');
}
/*****************************
 All rights reserved.
 AllFont.ru (c) 2011-2015
 *****************************/
@font-face {
  font-family: 'Europe Bold';
  font-style: normal;
  font-weight: 700;
  src: local('Europe Bold'), local('Europe-Bold'),
  url("/fonts/europe-bold_b1ad4a886b5cb8885050f7678eaabf09.woff") format('woff'),
  url("/fonts/europe-bold_b1ad4a886b5cb8885050f7678eaabf09.ttf") format('truetype');
}

$primary: #343434;
$secondary: #fff;
$tertiary: #FFF500;

$background-primary: #343434;
$background-secondary: #777;
$background-light: #D0D0D0;
$background-lighter: #fff;

$novelty: #E13434;

$border: #D0D0D0;

$base-font: 'Europe Normal', arial;
$bold-font: 'Europe Bold', arial;

$max-width: 1150px;
