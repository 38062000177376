@import '../global/variables';
@import '../global/mixins';

.side-menu-profile {
  &--header {
    margin: 12px 0;
    padding: 10px 0 10px 40px;
    background-color: $tertiary;

    font-family: $base-font;
    @include font-size-18;
    color: $primary;
  }

  &--link {
    display: block;
    padding: 8px 8px 8px 50px;

    font-family: $base-font;
    @include font-size-16;
    color: $secondary;
    text-decoration: none;
    transition: 0.3s;

    &:hover {
      color: $novelty;
    }
  }
}
